import request from '@/utils/request';


// 获取列表
export function List(params) {
  return request({
      url: '/wisc/youth/report/page',
      method: 'GET',
      params: params
  });
}

// 关联数据
export function Binding(params) {
  return request({
      url: '/wisc/youth/report/binding',
      method: 'GET',
      params: params
  });
}

<template>
  <div class="main-container">
    <div class="main-header">
      <div class="logo-box">
        <img src="/images/logo.png" alt="">
      </div>
      <div class="name-box">韦氏儿童智力量表第四版中文版计分系统</div>
      <topMenu :num="3"></topMenu>
    </div>
    <div class="main-body">
      <div class="content-box">
        <div class="tabs-box">
          <div class="tabs-item" @click="goModifyData">个人资料修改</div>
          <div class="tabs-item" @click="goModifyPassword">修改密码</div>
          <div class="tabs-item active">历史报表查询</div>
          <div class="tabs-item" style="margin-left: 20px;width: 220px"><a style="color: #FFFFFF" href="http://wisc.king-may.com.cn/login.jhtml" target="_blank">2024年9月2日前报告查询</a></div>
        </div>
        <div class="form-box">
          <div class="body">
            <div class="search-box">
              <div class="search-form">
                <a-form-model layout="inline">
                  <a-form-model-item label="诊疗卡号">
                    <a-input allowClear placeholder="" class="cu-input input-cuw" v-model="page.archives_num"/>
                  </a-form-model-item>
                  <a-form-model-item label="儿童姓名">
                    <a-input allowClear placeholder="" class="cu-input input-cuw" v-model="page.name"/>
                  </a-form-model-item>
                  <a-form-model-item label="报告编号">
                    <a-input allowClear placeholder="" class="cu-input input-cuw" v-model="page.scoring_num"/>
                  </a-form-model-item>

                  <a-form-model-item label="测试类型">
                    <a-select allowClear placeholder="请选择" v-model="page.edition" style="width: 150px">
                      <a-select-option value="4">完整版</a-select-option>
                      <a-select-option value="5">简版</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item>
                    <a-range-picker style="width:220px" allowClear @change="changeRangeDate" format="YYYY-MM-DD"/>
                  </a-form-model-item>
                  <a-form-model-item>
                    <span class="search-btn" @click="getList()"><img src="/images/search.png" alt=""></span>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </div>
            <div class="list-box">
              <a-table :columns="columns" :data-source="list" :pagination="false">
                <template slot="gender" slot-scope="text, record">
                  <span v-if="text == 1">男</span>
                  <span v-if="text == 2">女</span>
                </template>
                <template slot="edition" slot-scope="text, record">
                  <span v-if="text == 4">完整版</span>
                  <span v-if="text == 5">简版</span>
                </template>
                <template slot="action" slot-scope="text, record" style="display: flex;align-items: center">
                  <a class="op-box" target="_blank" :href="'http://' + record.down_pdf_url" download="pdf"><img src="/static/download.png" style="width: 20px;height: 20px"
                                                                                                                alt=""><span>下载</span></a>
                  <span class="de-line"></span>
                  <span class="op-box" @click.stop="goEdit(record, 'history')"><img src="/static/edit.png"
                                                                         style="width: 14px;height: 14px"
                                                                         alt=""><span>编辑</span></span>
                  <span class="de-line"></span>
                  <span class="op-box" @click.stop="openDetail(record)"><img src="/static/eye.png"
                                                                                style="width: 16px;height: 16px"
                                                                                alt=""><span>详情</span></span>
                </template>
              </a-table>
            </div>
            <div class="content-page cu-page"
                 style="display: flex;align-items: center;justify-content: flex-end;margin-top: 10px">
              <a-pagination :hideOnSinglePage="true" v-model="page.start" :page-size.sync="page.pageSize"
                            :total="page.totalRow" show-less-items @change="changePage"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-bottom></footer-bottom>
    <detail :item="item" :detailVisible="detailVisible" @detailCancel="detailCancel"/>
    <bind-list @changeBindList="changeBindList" :id="id" :type="'select'" name="" gender="" born_time="" edition=""
               scoring_type="" :is-visible="bindListVisible"></bind-list>
  </div>
</template>

<script>
import * as Api from './api';
import {GetInfo} from '../../commonApi';
import {message} from "ant-design-vue";
import moment from 'moment';

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API,
      login_code: window.localStorage.getItem('login_code'),
      labelCol: {span: 6, style: {color: '#7E8589', fontWeight: '500'}},
      wrapperCol: {span: 14},
      columns: [
        {
          title: '诊疗卡号',
          dataIndex: 'archives_num',
          key: 'archives_num',
          scopedSlots: {customRender: 'archives_num'},
        },
        {
          title: '报告编号',
          dataIndex: 'scoring_num',
          key: 'scoring_num',
          scopedSlots: {customRender: 'scoring_num'},
        },
        {
          title: '儿童姓名',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: {customRender: 'name'},
        },
        {
          title: '测试类型',
          dataIndex: 'edition',
          key: 'edition',
          scopedSlots: {customRender: 'edition'},
        },
        {
          title: '性别',
          dataIndex: 'gender',
          key: 'gender',
          scopedSlots: {customRender: 'gender'},
        },
        {
          title: '出生日期',
          dataIndex: 'born_date',
          key: 'born_date',
          scopedSlots: {customRender: 'born_date'},
        },
        {
          title: '测试时间',
          dataIndex: 'test_date',
          key: 'test_date',
          scopedSlots: {customRender: 'test_date'},
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          key: 'create_time',
          scopedSlots: {customRender: 'create_time'},
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: {customRender: 'action'},
          width: 200
        },
      ],
      page: {
        start: 1,
        limit: 10,
        totalRow: 0,
        pageSize: 0,
        name: '',
        archives_num: '',
        scoring_num: '',
        edition: undefined,
        isToday: false,
        startTime: '',
        endTime: ''
      },
      list: [],
      bindListVisible: false,
      id: '',
      detailVisible: false,
      item: {}
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    goModifyPassword() {
      this.$router.push({path: '/maintain-password-modify'});
    },
    goModifyData() {
      this.$router.push({path: '/maintain-data-modify'});
    },
    async getList() {
      const res = await Api.List(this.page);
      if (res && res.code == 0) {
        this.list = res.page.list;
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
      }
    },
    changePage(e) {
      this.page.start = e;
      this.getList();
    },
    openBindList(id) {
      this.bindListVisible = true;
      this.id = id;
    },
    changeBindList(e) {
      if (e.type == 'cancel') {
        this.bindListVisible = false;
      } else if (e.type == 'select') {
        this.bind(e.data.id);
      }
    },
    async bind(id) {
      const res = await Api.Binding({binding_id: id, id: this.id});
      if (res && res.code == 0) {
        message.success({content: res.message});
        this.bindListVisible = false;
        this.getList();
      }
    },
    goEdit(record, source) {
      if (record.is_edit != 1) {
        message.error({content: '报告只能编辑一次'});
        return;
      }
      if (record.edition == 1) {
        this.$router.push({path: '/children', query: {id: record.id, source: source}})
      } else if (record.edition == 2) {
        this.$router.push({path: '/youth', query: {id: record.id, source: source}})
      } else if (record.edition == 3) {
        this.$router.push({path: '/juvenile', query: {id: record.id, source: srouce}})
      }
    },
    changeRangeDate(e, s) {
      console.log(s)
      if (s) {
        this.page.startTime = s[0];
        this.page.endTime = s[1];
      } else {
        this.page.startTime = '';
        this.page.endTime = '';
      }
    },
    openDetail(item) {
      this.item = item;
      this.detailVisible = true;
    },
    detailCancel() {
      this.detailVisible = false;
    },
  }
};
</script>
<style>
.ant-table-thead > tr > th {
  color: #832C75 !important;
  border-top: 2px solid #832C75;
  border-bottom: none;
  background-color: #F2F3F5;
}

.ant-progress-success-bg, .ant-progress-bg {
  background-color: #832C75;
}

</style>
<style scoped>
.main-body {
  background-image: url("/images/maintain-bg.png");
  align-items: flex-start;
  justify-content: flex-start;
}

.content-box {
//margin: 50px; width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form-box {
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0px 8px 25px 0px rgba(242, 243, 245, 0.28);
  border-radius: 0px 12px 12px 12px;
  height: 100%;
  overflow: scroll;
}

.btn {
  background: #832B75;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  padding: 10px 40px;
}

.name {
  color: #656565;
  font-size: 14px;
  font-weight: 400;
  color: #4A4E51;
}

.search-box {
  display: flex;
}

.search-box .search-form {
  flex: 1;
}

.search-btn {
  background-color: #D08846 !important;
  padding: 0px 15px !important;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
}

.search-btn img {
  width: 14px;
}

.search-add {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 110px;
}

.list-box {
  margin-top: 40px;
}

.de-line {
  border-right: 1px solid #ddd;
  height: 4px;
  margin: 0 10px;
}

.op-box {
  cursor: pointer;
  color: #666666;
  font-size: 12px;
}
</style>
